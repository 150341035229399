<template>
  <div class="row">
    <div class="col-12">
      <div
        class="card card-custom gutter-b example example-compact"
        v-if="filter"
      >
        <div class="card-header">
          <!-- <div class="row align-items-center">
            <div class="col-lg-10 col-xl-8"> -->
          <div class="row align-items-center px-0 pa-8">
            <div class="col-md-3 my-2 my-md-0">
              <div class="input-icon">
                <input
                  type="text"
                  v-debounce:600ms="onFilterInputChange"
                  class="form-control"
                  placeholder="nomi"
                  v-model="sections.filters.full_name"
                />
                <span>
                  <i class="flaticon2-search-1 text-muted"></i>
                </span>
              </div>
            </div>
            <div class="col-md-3 my-2 my-md-0">
              <div class="input-icon">
                <input
                  type="text"
                  v-debounce:600ms="onFilterInputChange"
                  class="form-control"
                  placeholder="INN"
                  @keypress="isNumberKey"
                  v-model="sections.filters.inn"
                />
                <span>
                  <i class="flaticon2-search-1 text-muted"></i>
                </span>
              </div>
            </div>
            <div class="col-md-3 my-2 my-md-0">
              <div class="input-icon">
                <input
                  type="text"
                  v-debounce:600ms="onFilterInputChange"
                  class="form-control"
                  placeholder="OKED"
                  @keypress="isNumberKey"
                  v-model="sections.filters.oked"
                />
                <span>
                  <i class="flaticon2-search-1 text-muted"></i>
                </span>
              </div>
            </div>
            <div class="col-md-3 my-2 my-md-0">
              <!-- <div class="input-icon"> -->
              <select
                v-model="sections.filters.juridical_type"
                @change="onFilterInputChange"
                class="form-control"
              >
                <option value="" selected>Turi</option>
                <option value="YU">Yuridik shaxs</option>
                <option value="JI">Jismoniy shaxs</option>
              </select>

              <!-- <input
                  type="text"
                  v-debounce:600ms="onFilterInputChange"
                  class="form-control"
                  placeholder="Turi"
                  v-model="sections.filters.juridical_type"
                />
                <span>
                  <i class="flaticon2-search-1 text-muted"></i>
                </span> -->
              <!-- </div> -->
            </div>
            <div class="col-md-3 my-2 my-md-0">
              <!-- <div class="input-icon"> -->
              <!-- <input
                  type="text"
                  v-debounce:600ms="onFilterInputChange"
                  class="form-control"
                  placeholder="Status"
                  v-model="sections.filters.status"
                />
                <span>
                  <i class="flaticon2-search-1 text-muted"></i>
                </span> -->
              <!-- </div> -->
              <select
                v-model="sections.filters.status"
                @change="onFilterInputChange"
                class="form-control"
              >
                <option value="" selected>Status</option>
                <option
                  :key="item.id"
                  :value="item.id"
                  v-for="item in clientStatus"
                >
                  {{ item.status }}
                </option>
              </select>
            </div>

            <div class="col-12">
              <!-- @click="reset"  -->
              <v-btn @click="resetFilter" color="error"
                >{{ $t('BREADCRUMBS.CLEAN') }}
              </v-btn>
            </div>
          </div>
          <!-- </div>
          </div> -->
        </div>
      </div>

      <div class="card card-custom gutter-b example example-compact">
        <div
          class="card-header d-flex align-items-center justify-content-between"
        >
          <div class="card-title">
            <h3 class="card-label">
              {{ $t('MENU.ENTERPRICE.PROVIDERS.LIST') }}
            </h3>
          </div>

          <div class="card-button">
            <v-btn color="warning mx-2" @click="filter = !filter">
              {{ $t('BREADCRUMBS.SEARCH') }}

              <v-icon>mdi-magnify</v-icon>
            </v-btn>
            <router-link to="/providerreg">
              <v-btn color="success"> Поставщик +</v-btn>
            </router-link>
          </div>
        </div>

        <div class="card-body">
          <v-data-table
            :headers="headers"
            :loading="isLoading"
            loading-text="Malumotlar yuklanmoqda..."
            no-data-text="Malumotlar topilmadi!"
            :items="allProviders"
            hide-default-footer
            single-select
            @click:row="rowClick"
            item-key="id"
            class="elevation-1 row-pointer"
          >
            <template v-slot:[`item.item`]="{ item }">
              <span
                v-if="item.status.status !== null"
                style="padding: 5px 10px"
                class="status__name"
                :class="item.status_class_name"
              >
                {{ item.status.status }}
              </span>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <ProvidersAction :index="item"></ProvidersAction> </template
          ></v-data-table>
          <div class="text-left mt-5">
            <v-pagination
              v-model="currentPage"
              :total-visible="7"
              @input="getPostData(currentPage)"
              :length="Math.ceil(getProvidersCount.count / 10)"
            ></v-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ProvidersAction from '@/view/content/dropdown/ProvidersAction.vue'
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import { mapState, mapActions } from 'vuex'
import { takeFilledKeyValue, isNumberKey } from '@/utils'

export default {
  data() {
    return {
      counter: 0, // count the clicks
      timer: null, // Needs to be specified here so it can be accessed on both clicks
      selectedId: -1,
      perPage: 10,
      currentPage: 1,

      filter: false,
      sections: {
        filters: {
          full_name: '',
          inn: '',
          oked: '',
          juridical_type: '',
          status: ''
        }
      }
    }
  },
  components: {
    ProvidersAction
  },
  created() {
    if (this.$route.query.page !== undefined) {
      this.getPostData(parseInt(this.$route.query.page, 10))
      this.currentPage = parseInt(this.$route.query.page, 10)
    } else {
      this.getPostData(this.currentPage)
    }
  },
  computed: {
    ...mapState({
      clientStatus: (state) => state.requests.clientStatus || []
    }),
    headers() {
      return [
        {
          text: 'ID',
          value: 'id'
        },
        {
          text: this.$t('TABLE_HEADER.STAFF_NAME_SURNAME'),
          value: 'full_name'
        },
        {
          text: this.$t('TABLE_HEADER.INN'),
          value: 'inn'
        },
        {
          text: this.$t('TABLE_HEADER.OKED'),
          value: 'oked'
        },
        {
          text: this.$t('TABLE_HEADER.TYPE'),
          value: 'juridical_type_name'
        },
        {
          text: this.$t('TABLE_HEADER.STATUS'),
          value: 'item'
        },
        {
          text: '',
          value: 'actions',
          sortable: false
        }
      ]
    },
    isLoading() {
      return this.$store.state.requests.isLoading
    },
    allProviders() {
      return this.$store.state.requests.allProviders.results
    },
    getProvidersCount() {
      return this.$store.state.requests.allProviders
    }
  },
  methods: {
    takeFilledKeyValue,
    isNumberKey,

    ...mapActions(['getAllProviders', 'getClientStatus']),

    onFilterInputChange() {
      const params = takeFilledKeyValue(this.sections.filters)
      // console.log('params', params)

      this.getAllProviders(params)
    },

    resetFilter() {
      Object.keys(this.sections.filters).forEach((key) => {
        if (key in this.sections.filters) {
          this.sections.filters[key] = ''
        }
      })
    },

    rowClick(item, row) {
      var self = this
      this.counter++
      if (this.counter == 1) {
        this.timer = setTimeout(function () {
          self.counter = 0
          row.select(true)
          this.selectedId = item.id
        }, 200)
      } else {
        clearTimeout(this.timer)
        self.counter = 0
        this.$router.push('/providerdetails/' + item.id + '/')
        row.select(false)
      }
    },
    getPostData(value) {
      // this.$store.dispatch("getAllProvidersPag", value);
      // if (this.$router.cu)
      if (parseInt(this.$router.currentRoute.query.page, 10) !== value) {
        this.$router.push({
          path: this.$router.currentRoute.path,
          query: { page: value }
        })
      }
      this.$store.dispatch('getAllProvidersPag', value)
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t('MENU.ENTERPRICE.TITLE') },
      { title: this.$t('MENU.ENTERPRICE.PROVIDERS.TITLE') },

      { title: this.$t('MENU.ENTERPRICE.PROVIDERS.LIST') }
    ])

    this.getClientStatus()
  },
  props: {
    index: Number
  }
}
</script>

<style scoped>
.status__name {
  border-radius: 5px;
  text-align: center;
  color: #fff;
  padding: 1px 4px;
}
.payment__name {
  font-weight: bold;
}
.v-application .elevation-1 {
  box-shadow: none !important;
}

.v-data-table /deep/ .v-data-table__wrapper > table > thead > tr > th {
  font-size: 1rem !important;
  color: #000 !important;
  font-weight: 500 !important;
}
table {
  border-collapse: collapse;
  width: 100%;
}
.v-data-table /deep/ .v-data-table__wrapper > table > tbody > tr > td {
  font-size: 1rem !important;
  color: #000 !important;
  height: 60px !important;
}
.v-data-table /deep/ .v-data-table__wrapper {
  overflow-x: scroll !important;
  white-space: nowrap !important;
  width: 100%;
}
</style>
